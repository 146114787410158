import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { IoFilter } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteJob, GetAllJobs, updateJob } from "../../Redux/jobs/job.action";
import {
  applyMode,
  GovTypes,
  initialStates,
  jobType,
  Sectors,
} from "../../utils/constants";
import Add from "./Add";
import { formatDate } from "../../utils/common_func";

const Job = () => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTxt, setSearchTxt] = useState("");
  const [filter, setFilter] = useState({});
  const [dateRange, setDateRange] = useState({ from: "", to: "" });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { AllJobs } = useSelector((state) => state.JobReducer);

  useEffect(() => {
    dispatch(GetAllJobs({ page: page + 1, limit: rowsPerPage }));
  }, [dispatch, page, rowsPerPage]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleFilterModalOpen = () => {
    setFilter({});
    setIsFilterModalOpen(true);
  };
  const handleFilterModalClose = () => setIsFilterModalOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(() => newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSwitch = (id, e, eventType) => {
    const data = {};
    if (eventType === "live") data.is_live = e.target.checked;
    if (eventType === "admitcard") data.is_admitcard_avl = e.target.checked;
    if (eventType === "results") data.is_results_avl = e.target.checked;

    dispatch(updateJob(id, data)).then((res) => {
      if (res === "SUCCESS") {
        toast.success("Post updated successfully!");
        dispatch(GetAllJobs());
      } else {
        toast.error("Something went wrong. Please try again later");
      }
    });
  };

  const handleEdit = (id) => {
    navigate(`/jobs/edit/${id}`);
  };

  const handleDelete = () => {
    dispatch(deleteJob(deleteId)).then((res) => {
      if (res === "SUCCESS") {
        toast.success("Post Deleted successfully!");
        dispatch(GetAllJobs());
      } else {
        toast.error("Something went wrong. Please try again later");
      }
      setIsDeleteModal(false);
      setDeleteId(null);
    });
  };

  const search = () => {
    dispatch(GetAllJobs({ search: searchTxt, limit: 100 }));
  };

  const handleFilter = (e) => {
    console.log(filter, "filter");
    e.preventDefault();
    dispatch(GetAllJobs({ ...filter, limit: 100 }));
    setIsFilterModalOpen(false);
  };

  useEffect(() => {
    if (dateRange.from && dateRange.to) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        date_range: `${dateRange.from}, ${dateRange.to}`,
      }));
    }
  }, [dateRange]);

  return (
    <Box sx={{ mt: "3rem", mx: { xs: "1rem", sm: "2rem", md: "3rem" } }}>
      <Typography variant="h4" gutterBottom>
        Job Posts
      </Typography>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "stretch", sm: "center" }}
        sx={{ mb: 2, gap: { xs: 2, sm: 0 } }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={toggleDrawer(true)}
          sx={{
            borderRadius: 0,
            display: "flex",
            alignItems: "center",
            mb: { xs: 1, sm: 0 },
          }}
        >
          <IoMdAdd />
          ADD NEW POST
        </Button>
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          gap="10px"
        >
          <Input
            placeholder="Search by title"
            sx={{ borderRadius: 0, mb: { xs: 1, sm: 0 } }}
            onChange={(e) => setSearchTxt(e.target.value)}
          />
          <Button
            variant="contained"
            sx={{ borderRadius: 0, mb: { xs: 1, sm: 0 } }}
            onClick={search}
          >
            Search
          </Button>
          <Button
            variant="outlined"
            sx={{ borderRadius: 0, display: "flex", alignItems: "center" }}
            onClick={handleFilterModalOpen}
          >
            <IoFilter />
            <Typography>Filter</Typography>
          </Button>
        </Box>
      </Box>

      <TableContainer
        sx={{
          mt: 2,
          border: "1px solid gray",
          overflowX: "auto",
        }}
      >
        <Table fontSize={{ xs: "10px", sm: "12px" }}>
          <TableHead>
            <TableRow
              sx={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#1976d2",
              }}
            >
              <TableCell sx={{ color: "white" }}>Title</TableCell>
              <TableCell sx={{ color: "white" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {AllJobs?.posts?.length === 0 ? (
              <TableRow sx={{ textAlign: "center" }}>
                No data available
              </TableRow>
            ) : (
              AllJobs?.posts?.map((job, i) => (
                <TableRow
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: `${i % 2 === 0 ? "#c9d4e7" : "white"}`,
                  }}
                  key={job?._id}
                >
                  <TableCell
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleEdit(job._id)}
                  >
                    <Typography>{job?.vacancy_title}</Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        marginTop: "5px",
                        color: "gray",
                      }}
                    >
                      Created: {formatDate(job?.createdAt, false)} By:{" "}
                      {job?.created_by?.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Switch
                      defaultChecked={job?.is_live}
                      color="success"
                      onChange={(e) => handleSwitch(job?._id, e, "live")}
                    />
                    <IconButton
                      color="error"
                      onClick={() => {
                        setIsDeleteModal(true);
                        setDeleteId(job?._id);
                      }}
                    >
                      <MdDelete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            // component="div"
            count={AllJobs?.total || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Table>
      </TableContainer>

      {/* Add New Post Component */}
      <Add isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} mb={"rem"} />

      {/* Filter Modal */}
      <Modal
        open={isFilterModalOpen}
        onClose={handleFilterModalClose}
        aria-labelledby="filter-modal-title"
        aria-describedby="filter-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "70%", md: "50%" },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="filter-modal-title" variant="h6" component="h2">
            Filter Jobs
          </Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {/* Filter by date range */}
            <Grid item xs={12} md={6}>
              <FormLabel>Post Create: From</FormLabel>
              <Input
                fullWidth
                type="date"
                name="from"
                value={dateRange.from}
                onChange={(e) =>
                  setDateRange({ ...dateRange, from: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel>To</FormLabel>
              <Input
                fullWidth
                type="date"
                name="to"
                value={dateRange.to}
                onChange={(e) =>
                  setDateRange({ ...dateRange, to: e.target.value })
                }
              />
            </Grid>

            {/* sector */}
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="filter-sector-label">Sector</InputLabel>
                <Select
                  value={filter.sector || ""}
                  labelId="filter-sector-label"
                  label="Sector"
                  onChange={(e) =>
                    setFilter({ ...filter, sector: e.target.value })
                  }
                >
                  {Sectors?.map((sector) => (
                    <MenuItem key={sector} value={sector}>
                      {sector}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* gov type */}
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="filter-gov-type-label">
                  Government Type
                </InputLabel>
                <Select
                  labelId="filter-gov-type-label"
                  label="Government Type"
                  value={filter.gov_type || ""}
                  onChange={(e) =>
                    setFilter({ ...filter, gov_type: e.target.value })
                  }
                >
                  {GovTypes?.map((govType) => (
                    <MenuItem key={govType} value={govType}>
                      {govType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* state */}
            {filter?.gov_type === "state" && (
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="filter-state-label">State</InputLabel>
                  <Select
                    labelId="filter-state-label"
                    label="State"
                    value={filter.states || ""}
                    onChange={(e) =>
                      setFilter({ ...filter, states: e.target.value })
                    }
                  >
                    {initialStates?.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {/* Application Mode */}
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="filter-appmode-label">
                  Application Mode
                </InputLabel>
                <Select
                  labelId="filter-appmode-label"
                  label="Application Mode"
                  value={filter.apply_mode || ""}
                  onChange={(e) =>
                    setFilter({ ...filter, apply_mode: e.target.value })
                  }
                >
                  {applyMode?.map((mode) => (
                    <MenuItem key={mode} value={mode}>
                      {mode}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Job type */}
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="filter-job-type-label">Job Type</InputLabel>
                <Select
                  labelId="filter-job-type-label"
                  label="Job Type"
                  value={filter.job_type || ""}
                  onChange={(e) =>
                    setFilter({ ...filter, job_type: e.target.value })
                  }
                >
                  {jobType?.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* live */}
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="filter-live-label">Live</InputLabel>
                <Select
                  labelId="filter-live-label"
                  label="Government Type"
                  value={filter.is_live || ""}
                  onChange={(e) =>
                    setFilter({ ...filter, is_live: e.target.value })
                  }
                >
                  <MenuItem value={"true"}>True</MenuItem>
                  <MenuItem value={"false"}>false</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Deleted */}
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="filter-deleted-label">Deleted</InputLabel>
                <Select
                  labelId="filter-deleted-label"
                  label="is deleted"
                  value={filter.is_deleted || ""}
                  onChange={(e) =>
                    setFilter({ ...filter, is_deleted: e.target.value })
                  }
                >
                  <MenuItem value={"true"}>True</MenuItem>
                  <MenuItem value={"false"}>false</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button variant="contained" color="primary" onClick={handleFilter}>
              Apply Filters
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Delete confirmation Modal */}
      <Modal
        open={isDeleteModal}
        onClose={() => setIsDeleteModal(false)}
        aria-labelledby="filter-modal-title"
        aria-describedby="filter-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "70%", sm: "50%", md: "25%" },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography
            id="filter-modal-title"
            variant="h6"
            component="h2"
            marginBottom={3}
          >
            Are you sure you want to delete this Post?
          </Typography>
          <Button
            onClick={() => setIsDeleteModal(false)}
            sx={{ bgcolor: "gray", color: "white", marginRight: "10px" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDelete(deleteId)}
            sx={{ bgcolor: "red", color: "white" }}
          >
            Delete
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Job;

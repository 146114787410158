export const formatDate = (dateStr, isTime) => {
  // Create a new Date object from the string
  const date = new Date(dateStr);

  // Format the date as DD-MM-YYYY in UTC
  const formattedDate = `${date.getUTCDate().toString().padStart(2, "0")}.${(
    date.getUTCMonth() + 1
  )
    .toString()
    .padStart(2, "0")}.${date.getUTCFullYear()}`;

  if (isTime) {
    // Format the time in 12-hour format with AM/PM in UTC
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const period = hours >= 12 ? "PM" : "AM";
    const formattedTime = `${hours % 12 || 12}:${minutes} ${period}`;

    return `${formattedDate} || ${formattedTime}`;
  }

  return `${formattedDate}`;
};
